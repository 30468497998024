<template>
  <v-container>
    <v-card class="pa-6">
      <v-card-title>
        {{ name }}
      </v-card-title>

      <UserProfileData
        :userProfilingId="userProfilingId"
        class="px-4"
      />
    </v-card>
  </v-container>
</template>

<script>
import UserProfileData from "../../components/UserProfileData.vue"

export default {
  components: {
    UserProfileData
  },
  data() {
    return {
      dashboardViewDataPath: this.$route.params.dashboardViewDataPath,
      name: this.$route.params.name,
      userProfilingId: this.$route.params.userProfilingId
    }
  },
  mounted() {
    if (!this.userProfilingId) {
      this.$router.push({ name: "Root" })
      this.$clearLoader()
    }
  }
}
</script>

<style>
.disable-events {
  pointer-events: none
}
</style>